import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import GlobalConstants from "../helpers/constants";
import { Grid, Typography } from "@material-ui/core";
import { GetComponentByName } from "../helpers/query-data";
import { graphql } from "gatsby";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { PageProps } from "gatsby";
import { GQLQuery } from "../types";

type PageInputProps = { crystallize: GQLQuery };
type AboutProps = PageProps<PageInputProps>;

const About = (props: AboutProps): JSX.Element => {
  const QueryData = props.data.crystallize.catalogue;
  const PathObj = GetSimplePathObj(GlobalConstants.AboutUs);
  const ContentComponent = GetComponentByName(
    QueryData.components,
    GlobalConstants.Content
  );

  return (
    <Layout currentPage="/about">
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{QueryData.name}</Typography>
        {ContentComponent.content.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue(path: "/articles/about-us") {
        ...AU_item
      }
    }
  }

  fragment AU_item on CRYSTALLIZE_Item {
    name
    components {
      ...AU_component
    }
  }

  fragment AU_component on CRYSTALLIZE_Component {
    name
    content {
      ...AU_singleLine
      ...AU_richText
    }
  }

  fragment AU_singleLine on CRYSTALLIZE_SingleLineContent {
    text
  }

  fragment AU_richText on CRYSTALLIZE_RichTextContent {
    html
  }
`;

export default About;
